import React, { Component } from 'react'
import Button from './_shared/Button'
import styled from 'styled-components'

export class LetsTalk extends Component {
  state = {
    showEmail: false,
    email: '',
    emailValid: false,
    emailPrompt: false,
  }

  validateEmail = (email = '') => {
    const check = /^.+@.+\..+$/.test(email)
    this.setState({ emailValid: check })
    if (check) {
      this.setState({ emailPrompt: false })
    }
    return check
  }

  updateEmail = event => {
    const email = event.target.value
    const emailValid = this.validateEmail(email)
    this.setState({ email, emailValid })
  }

  render() {
    const { emailValid } = this.state

    return (
      <Wrapper id="tf-lets-talk" className="bg-inverse">
        <div className="container text-center">
          <div className="row">
            <div className=" col-md-12">
              {/*<h1>LET'S TALK</h1>*/}
              <form
                action="https://formspree.io/msawka@thebrain.pro"
                method="POST"
              >
                <div className="container text-center">
                  <div className="row">
                    <div className="col-sm-3" style={{ marginTop: 100 }}>
                      <label>
                        <span>message</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <textarea
                        name="message"
                        placeholder="Tell us a few words about your project. We will answer today!"
                        onClick={() => {
                          this.setState({ showEmail: true })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <label className={'open'}>
                        <span>Email</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.updateEmail}
                      />
                    </div>
                  </div>
                </div>
                {/*<label className={this.state.showEmail ? 'open' : ''}>*/}
                <div className="clearfix" />
                {emailValid && <Button type="submit">SEND</Button>}
              </form>

              {this.state.emailPrompt && (
                <div className="warning">
                  Please provide correct email address
                </div>
              )}

              {!emailValid && (
                <Button
                  style={{ width: '100%' }}
                  type="button"
                  onClick={() => {
                    this.setState({ emailPrompt: true })
                  }}
                >
                  SEND
                </Button>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .clearfix {
    min-width: 100%;
  }

  button {
    margin: 20px auto;
  }

  .container .row > div {
    padding: 0;
  }

  textarea {
    margin-top: 100px;
    border: 2px solid #00d8ff;
    width: 100%;
    min-height: 150px;
    font-size: 1.3em;
    padding: 10px;
  }

  input {
    margin-top: 5px;
    border: none;
    border: 2px solid #00d8ff;
    font-size: 1.3em;
    width: 100%;
  }

  label,
  label span {
    width: 100%;
    text-align: right;
    margin: 10px 10px 0 0;
    transition: 0.5s;
    // overflow: hidden;
  }

  @media (max-width: 768px) {
    label,
    label span {
      text-align: left;
    }

    textarea {
      margin-top: 20px;
    }

    .container .row > div {
      padding: 0 10px;
    }
  }

  //label.open,
  //label.open span {
  //  transition: 0.5s;
  //height: 100%;
  //}
`

export default LetsTalk
