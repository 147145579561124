import React, { Component } from 'react'
import logoFooter from '../designs/dist/img/logo-footer.png'
import LetsTalk from './LetsTalk'
import styled from 'styled-components'

export class Footer extends Component {
  state = {
    isFormOpen:
      (this.props.location &&
        this.props.location.href &&
        this.props.location.href.split('#')[1]) ||
      false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.state.isFormOpen &&
      (this.props.location &&
        this.props.location.href &&
        this.props.location.href.split('#')[1])
    ) {
      this.setState({ isFormOpen: true })
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="footer-blue">
          <div className="container">
            <div className="row">
              <div id="contact" />
              <a
                // ahref={'mailTo: team@thebrain.pro'}
                onClick={() =>
                  this.setState({ isFormOpen: !this.state.isFormOpen })
                }
              >
                let’s talk <span className="blink"> > </span>
              </a>
            </div>
          </div>
        </div>
        {this.state.isFormOpen && (
          <div className="container">
            <div className="row">
              <LetsTalk />
            </div>
          </div>
        )}

        <section className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0">
                <div className="row w-100 d-flex justify-content-start align-items-center flex-row pt-5">
                  <div className="col-12 col-md-4">
                    <img src={logoFooter} alt="logo-footer" />
                  </div>
                  <div className="col-12 col-md-8">
                    <label>
                      THE
                      <span>BRAIN SOFTWARE</span>
                      HOUSE
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-2 footer-menu">
                <h5>HEADQUARTERS</h5>
                <p>
                  <a style={{ color: '#fff' }} href={'tel:+48 604 584 176'}>
                    +48 604 584 176
                  </a>
                  <br />
                  Organowa 1/27
                  <br />
                  20-882 Lublin
                  <br />
                  Poland
                </p>
              </div>
              <div className="col-12 col-lg-3 footer-menu">
                <h5>US OFFICE:</h5>
                <p>
                  <a style={{ color: '#fff' }} href={'tel:(952) 444-3152'}>
                    (952) 444-3152
                  </a>
                  <br />
                  7900 International Drive
                  <br />
                  Suite 300 - Bloomington, MN 55425
                  <br />
                  United States
                </p>
              </div>
              <div className="col-12 col-lg-3 footer-menu">
                <h5>UAE BRANCH:</h5>
                <p>
                  <a style={{ color: '#fff' }} href={'tel:+971 50 899-5016'}>
                    +971 50 899-5016
                  </a>
                  <br />
                  Krypto Labs, <br />
                  Masdar City - Abu Dhabi
                  <br />
                  United Arab Emirates
                </p>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  .footer-blue .row {
    width: 100%;
  }

  .footer-blue a {
    max-width: 1024px;
    background-color: #0094cf;
    text-decoration: none;
    position: relative;
    top: 50px;
    z-index: 5;
    text-transform: uppercase;
    //margin: 50px 20px 0 50px;
    font-size: 300%;
    padding: 20px 40px;
    color: #fff;
    font-weight: 700;
  }

  .footer-blue a:hover {
    cursor: pointer;
    color: #0094cf;
    background-color: white;
    box-shadow: 0px 0px 0px 10px #0094cf inset;
  }

  .footer-blue a:hover span.blink {
    color: #0094cf;
    -webkit-animation: 1s blink-hover step-end infinite;
    -moz-animation: 1s blink-hover step-end infinite;
    -ms-animation: 1s blink-hover step-end infinite;
    -o-animation: 1s blink-hover step-end infinite;
    animation: 1s blink-hover step-end infinite;
  }

  .footer-blue a i {
    float: right;
    margin-top: 13px;
  }

  .blink {
    color: transparent;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;

    &:hover {
      -webkit-animation: 1s blink-hover step-end infinite !important;
      -moz-animation: 1s blink-hover step-end infinite !important;
      -ms-animation: 1s blink-hover step-end infinite !important;
      -o-animation: 1s blink-hover step-end infinite !important;
      animation: 1s blink-hover step-end infinite !important;
    }

    @keyframes blink {
      from,
      to {
        color: transparent;
      }
      50% {
        color: white;
      }
    }

    @-moz-keyframes blink {
      from,
      to {
        color: transparent;
      }
      50% {
        color: white;
      }
    }

    @-webkit-keyframes blink {
      from,
      to {
        color: transparent;
      }
      50% {
        color: white;
      }
    }

    @-ms-keyframes blink {
      from,
      to {
        color: transparent;
      }
      50% {
        color: white;
      }
    }

    @-o-keyframes blink {
      from,
      to {
        color: transparent;
      }
      50% {
        color: white;
      }
    }
  }

  @keyframes blink-hover {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #0094cf;
    }
  }

  @-moz-keyframes blink-hover {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #0094cf;
    }
  }

  @-webkit-keyframes blink-hover {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #0094cf;
    }
  }

  @-ms-keyframes blink-hover {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #0094cf;
    }
  }

  @-o-keyframes blink-hover {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #0094cf;
    }
  }
`

export default Footer
