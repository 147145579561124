import React, { Component } from 'react'
import styled from 'styled-components'
import logo from '../img/logo.png'
import HamburgerMenu from 'react-hamburger-menu'
import PropTypes from 'prop-types'
import Script from 'react-load-script'
import { Link } from 'gatsby'

class MenuModal extends Component {
  state = {
    jQueryReady: false,
    bootstrapReady: false,
    slickReady: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const body = document.getElementsByTagName('body')[0]
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open')
      modalBackdrop.remove()
    }
  }

  render = () => {
    const { jQueryReady, bootstrapReady, slickReady } = this.state
    return (
      <>
        <Script
          url={'/jquery-3-3-1.min.js'}
          onLoad={() => this.setState({ jQueryReady: true })}
        />
        {jQueryReady && (
          <Script
            url={'/bootstrap.bundle.min.js'}
            onLoad={() => this.setState({ bootstrapReady: true })}
          />
        )}
        {bootstrapReady && (
          <Script
            url={'/slick/slick.min.js'}
            onLoad={() => this.setState({ slickReady: true })}
          />
        )}
        {this.state.jQueryReady && this.state.slickReady && (
          <Script url={'/custom.js'} />
        )}
        {jQueryReady && bootstrapReady && slickReady && (
          <div
            className={'modal fade' + this.props.menuVisible ? ' show' : ''}
            className={'modal fade'}
            id="menuModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            // aria-hidden={this.state.menuVisible ? "true" : "false"}
            // style={{
            //   display: this.state.menuVisible ? "block" : "none"
            // }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <span className="fas fa-times" />
              </span>
            </button>
            <div
              className={`plus big plus-2${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`plus small plus-1${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`plus big plus-3${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`plus small plus-4${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`plus small plus-5${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`circle big circle-1${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`circle small circle-2${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`circle medium single circle-3${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`circle big circle-4${
                this.props.menuVisible ? ' active' : ''
              }`}
            />
            <div
              className={`circle small single circle-5${
                this.props.menuVisible ? ' active' : ''
              }`}
            />

            <div
              className="container modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <ul className="navbar-nav d-flex justify-content-center align-items-end flex-column">
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.props.triggerScroll &&
                        this.props.triggerScroll('home')
                      }
                    >
                      <Link
                        className="nav-link"
                        to={`/${!this.props.triggerScroll ? '#home' : ''}`}
                        data-dismiss={this.props.triggerScroll && 'modal'}
                      >
                        HOME
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/team">
                        ABOUT
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.props.triggerScroll &&
                        this.props.triggerScroll('services')
                      }
                    >
                      <Link
                        className="nav-link"
                        to={`/${!this.props.triggerScroll ? '#services' : ''}`}
                        data-dismiss={this.props.triggerScroll && 'modal'}
                      >
                        SERVICES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/testimonials">
                        TESTIMONIALS
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/resources">
                        RESOURCES
                      </Link>
                    </li>

                    <li
                      className="nav-item"
                      onClick={() =>
                        this.props.triggerScroll &&
                        this.props.triggerScroll('contact')
                      }
                    >
                      <Link
                        className="nav-link"
                        to={`/${
                          !this.props.triggerScroll ? '#contact' : '/#contact'
                        }`}
                        data-dismiss={this.props.triggerScroll && 'modal'}
                      >
                        CONTACT
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

MenuModal.propTypes = {
  menuVisible: PropTypes.bool,
  triggerScroll: PropTypes.func,
}

export class Header extends Component {
  render() {
    return (
      <Wrapper>
        <div
          className={`${
            this.props.isHome ? 'top-menu fixed home' : 'top-menu fixed'
          }`}
        >
          <div className="container">
            <nav
              className="navbar navbar-expand-lg justify-content-between align-items-center d-flex flex-row"
              style={{ zIndex: 1000000 }}
            >
              <a className="navbar-brand" href={'/'}>
                <img src={logo} alt="logo" />
              </a>
              {this.props.title}
              <button
                className="navbar-toggler-icon"
                type="button"
                data-toggle="modal"
                data-target="#menuModal"
                onClick={this.props.toggleMenu}
              >
                <span
                  style={{
                    position: 'absolute',
                    color: 'white',
                    right: 70,
                    top: 10,
                  }}
                >
                  MENU
                </span>

                {/*THIS SHOULD BE ANIMATED LIKE IN DEMO http://cameronbourke.github.io/react-hamburger-menu/*/}
                <HamburgerMenu
                  isOpen={this.props.menuVisible}
                  menuClicked={this.props.toggleMenu}
                  width={30}
                  height={30}
                  strokeWidth={3}
                  rotate={0}
                  color="white"
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </button>
            </nav>
            {/* recreating some bootstrap functionality in js here */}
            <MenuModal
              menuVisible={this.props.menuVisible}
              toggleMenu={this.props.toggleMenu}
              triggerScroll={this.props.triggerScroll}
            />
          </div>
        </div>
      </Wrapper>
    )
  }
}

Header.propTypes = {
  toggleMenu: PropTypes.func,
  menuVisible: PropTypes.bool,
  triggerScroll: PropTypes.func,
  isHome: PropTypes.bool,
}

const Wrapper = styled.div``

export default Header

// const menuElements = [
//   {
//     to: '#tf-home',
//     text: 'Home',
//   },
//   {
//     to: '#tf-team-section',
//     text: 'Team',
//   },
//   {
//     to: '#tf-case-study',
//     text: 'Case study',
//   },
//   {
//     to: '#tf-testimonials',
//     text: 'Testimonials',
//   },
//   {
//     to: '#tf-open-source',
//     text: 'Open source',
//   },
//   {
//     to: '#tf-lets-talk',
//     text: 'Contact',
//   },
// ]
