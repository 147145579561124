import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

export const Button = props => (
  <Wrapper className="styled-button" onClick={props.onClick}>
    {props.href ? (
      <Link to={props.href}>{props.children}</Link>
    ) : (
      <div>{props.children}</div>
    )}
  </Wrapper>
)

const Wrapper = styled.button`
  background: #0094cf;
  border: none;
  letter-spacing: 2px;
  color: white !important;
  font-size: 1.2em;
  padding: 15px 30px;
  margin: auto;
  display: inline-block;

  a {
    color: white;
  }

  :focus {
    outline: none;
  }
  :hover {
    background: #2a83a4;

    a {
      text-decoration: none;
    }
  }
`

export default Button
