import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../designs/dist/css/bootstrap.min.css'
import '../designs/dist/js/slick/slick.css'
import '../designs/dist/css/main.min.css'

import Header from './Header'
import Footer from './Footer'

class Layout extends Component {
  render() {
    let { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query NEW_SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'TheBrain Software House' },
                {
                  name: 'keywords',
                  content:
                    'react, node, graphql, gql, application development, software house',
                },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <Header
              title={this.props.title}
              toggleMenu={this.props.toggleMenu}
              menuVisible={this.props.menuVisible}
              triggerScroll={this.props.triggerScroll}
              isHome={this.props.isHome}
            />
            {children}
            <Footer location={this.props.location} />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  triggerScroll: PropTypes.func,
  menuVisible: PropTypes.bool,
  isHome: PropTypes.bool,
}

export default Layout
